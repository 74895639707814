import PropTypes from 'prop-types';
import { Navigate, Route, Routes } from 'react-router-dom';

import Article from '../../pages/Article/Article';
import Home from '../../pages/Home';
import NoMatch from '../ErrorHandling/NoMatch';
import styles from './MainContainer.module.css';

const MainContainer = ({site_name, copyright, footer_logo }) => {
  return (
    <div className={styles.mainContainer}>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route
          path="/info/:slug"
          element={<Article site_name={site_name} copyright={copyright} footer_logo={footer_logo} />}
        />
        <Route exact path="/404" element={<NoMatch />} />
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Routes>
    </div>
  );
};

export default MainContainer;

MainContainer.propTypes = {
  copyright: PropTypes.string,
  footer_logo: PropTypes.string
};
