import React from 'react';
import { Helmet } from 'react-helmet-async';
// import { Link } from 'react-router-dom';

const NoMatch = () => {
  return (
    <>
      <Helmet>
        <title>DripDropTips | Not Found</title>
        <meta name="robots" content="noindex"></meta>
      </Helmet>
      <div className="not-found">
        <h1 className="not-found__title">404</h1>
        <div className="not-found__divider" />
        <p className="not-found__message">
          <span className="not-found__message--main">Uh oh! Page not found...</span>
          <span className="not-found__message--sub">
          The page you were looking for doesn&apos;t exist or might have been removed. Try going back
            to the{' '}
            <a href="/" className="not-found__link">
              homepage
            </a>.
          </span>
        </p>
        <style jsx>{`
          .not-found {
            width: 40%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            justify-content: center;
            align-items: stretch;
          }

          .not-found__title {
            margin: 0;
            align-self: center;
            font-family: 'Source Sans Pro', 'Segoe UI', 'Helvetica Neue', 'Arial';
            font-size: 5rem;
            color: #252668;
          }

          .not-found__divider {
            width: 2px;
            background-color: #5e88d6;
            margin: 0 1rem;
            content: '';
            clear: both;
            display: table;
          }

          .not-found__message {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            margin: 0;
            font-family: 'Source Sans Pro', 'Segoe UI', 'Helvetica Neue', 'Arial';
          }

          .not-found__message--main {
            font-size: 2rem;
            color: #5e88d6;
            margin-bottom: 0.8rem;
          }

          .not-found__message--sub {
            font-size: 1.1rem;
          }

          .not-found__link {
            border: none;
            background-color: inherit;
            color: #5e88d6;
            transition: all 0.2s ease;
          }

          .not-found__link:hover {
            border-bottom: 1px solid #42a2e3;
            color: #42a2e3;
            cursor: pointer;
            text-decoration: none;
          }
        `}</style>
      </div>
    </>
  );
};

export default NoMatch;
